import { Injectable } from '@angular/core';
import { StorageConst } from '../../constants/storage.const';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private access_token = StorageConst.access_token;
    private user_details = StorageConst.user_details;
    private refreshKey = StorageConst.refresh_token;
    private university_country = StorageConst.university_country;
    private all_countries = StorageConst.all_countries;
    private current_ip_location = StorageConst.current_ip_location;
    private upload_doc_restriction = StorageConst.upload_doc_restriction;
    private current_location_country = StorageConst.current_location_country;
    private university_country_list = StorageConst.university_country_list;
    private backUpUniCountry = StorageConst.backUpUniCountry;
    

    checkProfileUpdate = new BehaviorSubject<boolean>(false);
    checkProfileUpdate$ = this.checkProfileUpdate.asObservable();

    checkFlagUpdate = new BehaviorSubject<any>({});
    checkFlagUpdate$ = this.checkFlagUpdate.asObservable();

    setKey(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    getKey(key: string): string | null {
        return localStorage.getItem(key);
    }

    setUser(token: string): void {
        localStorage.setItem(this.user_details, JSON.stringify(token));
    }

    getUser(): string | any {
        return JSON.parse(localStorage.getItem(this.user_details));
    }

    setRefreshKey(token: string): void {
        localStorage.setItem(this.refreshKey, token);
    }

    getRefreshKey(): string | null {
        return localStorage.getItem(this.refreshKey);
    }

    removeKey(key: string): void {
        localStorage.removeItem(key);
    }

    clearAll(): void {
        localStorage.clear();
    }

    isAuthenticated(): boolean {
        const token = this.getKey(this.access_token);
        return !!token;
    }

    setUniversityCountry(value): any {
        localStorage.setItem(this.university_country, JSON.stringify(value));
    }

    getUniversityCountry(): string | null {
        return JSON.parse(localStorage.getItem(this.university_country));
    }

    setAllCountries(value): any {
        localStorage.setItem(this.all_countries, JSON.stringify(value));
    }

    getAllCountries(): string | null {
        return JSON.parse(localStorage.getItem(this.all_countries)) || [];
    }

    setIPLocation(value): any {
        localStorage.setItem(this.current_ip_location, JSON.stringify(value));
    }

    getIPLocation(): string | null {
        return JSON.parse(localStorage.getItem(this.current_ip_location));
    }

    setUploadDocRestriction(value): any {
        localStorage.setItem(
            this.upload_doc_restriction,
            JSON.stringify(value)
        );
    }

    getUploadDocRestriction(): string | null {
        return JSON.parse(localStorage.getItem(this.upload_doc_restriction));
    }

    setCurrentLocationCountry(value): any {
        localStorage.setItem(
            this.current_location_country,
            JSON.stringify(value)
        );
    }

    getCurrentLocationCountry(): string | null {
        return JSON.parse(localStorage.getItem(this.current_location_country));
    }

    setUniversityCountryList(value): any {
        localStorage.setItem(
            this.university_country_list,
            JSON.stringify(value)
        );
    }

    getUniversityCountryList(): string | null {
        return JSON.parse(localStorage.getItem(this.university_country_list));
    }

    setPassportVerificationDetails(value): any {
        localStorage.setItem(
            StorageConst.passport_verification_details,
            JSON.stringify(value)
        );
    }

    getPassportVerificationDetails(): string | null {
        return (
            JSON.parse(
                this.getKey(StorageConst.passport_verification_details)
            ) || ''
        );
    }

    saveApplicationSelection(obj) {
        return localStorage.setItem(
            StorageConst.saveApplicationStorage,
            JSON.stringify(obj)
        );
    }

    getApplicationSelection() {
        return (
            JSON.parse(
                localStorage.getItem(StorageConst.saveApplicationStorage)
            ) || ''
        );
    }

    setPagination(pgObj) {
        localStorage.setItem(
            StorageConst.paginationConst,
            JSON.stringify(pgObj)
        );
    }

    getPagination() {
        return (
            JSON.parse(localStorage.getItem(StorageConst.paginationConst)) || ''
        );
    }

    set slectedUniversityCountrt(country) {
        this.checkFlagUpdate.next(country);
    }
    get slectedUniversityCountrt() {
        return this.checkFlagUpdate.asObservable();
    }

    setUniversityCountryForCommission(value): any {
    localStorage.setItem(StorageConst.uniCountryForCommission, JSON.stringify(value));
    }

    getUniversityCountryForComission(): string | null {
    return JSON.parse(localStorage.getItem(StorageConst.uniCountryForCommission));
    }
    
    setUpBackUpCountry(value): any {
        localStorage.setItem(this.backUpUniCountry, JSON.stringify(value));
    }

    getUpBackUpCountry(): string | null {
        return JSON.parse(localStorage.getItem(this.backUpUniCountry));
    }
}
