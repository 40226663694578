import { environment } from 'src/environments/environment';
import { StorageConst } from '../constants/storage.const';

export function filterValue(
    passedArray: any[],
    passedControl,
    passedReplaySubject
) {
    if (!passedArray) {
        return;
    }
    // get the search keyword
    let search = passedControl.value;

    if (!search) {
        passedReplaySubject.next(passedArray.slice());
        return;
    } else {
        search = search.toLowerCase();
    }
    // filter the array
    passedReplaySubject.next(
        passedArray.filter(
            (supervisersName) =>
                supervisersName?.name?.toLowerCase().indexOf(search) > -1
        )
    );
}

export function filePathWithBaseURL(file: string) {
    file = `${environment.fileBaseURL}${file?.replaceAll(' ', '+')}`;
    return file;
}

export function viewDocument(file: string) {
    if (!file) {
        this._toasterService.showError(
            this.translate.instant('ERROR'),
            this.translate.instant('FILE_INVALID')
        );
        return;
    }

    const fileUrl = `${environment.fileBaseURL}${file.replaceAll(' ', '+')}`;
    const fileExtension = file.split('.').pop()?.toLowerCase();

    if (['doc', 'docx', 'pdf'].includes(fileExtension)) {
        const googleDocsViewerUrl = 'https://docs.google.com/viewer?url=';
        const encodedFileUrl = encodeURIComponent(fileUrl);
        window.open(
            `${googleDocsViewerUrl}${encodedFileUrl}&embedded=true`,
            '_blank'
        );
    } else if (
        ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension)
    ) {
        // For images, open in a new tab with the image displayed
        const imgWindow = window.open('', '_blank');
        imgWindow.document.write(`
            <img src="${fileUrl}" style="max-width: 100%; max-height: 100%; display: block; margin: auto;">
        `);
    } else {
        // For other file types, use the original method
        window.open(fileUrl);
    }
}

export function validateUploadDoc(module: string, size?: number) {
    let file_size;
    let file_size_invalid;
    let file_accept;
    let file_not_allowed;

    let restrictions = JSON.parse(
        localStorage.getItem(StorageConst.upload_doc_restriction)
    );
    if (restrictions?.length) {
        let find_module = restrictions.find(
            (element) => element.module === module
        );
        if (find_module) {
            file_size = size ? fileSizeAndExtension(size).size : '';
            file_size_invalid = size / (1024 * 1024) >= find_module.size;
            file_accept = find_module?.extension?.toString();
        } else {
            file_not_allowed = true;
        }
    }

    return { file_size, file_size_invalid, file_accept, file_not_allowed };
}

export function decodeComment(input: string): string {
    if (!input) {
        return '';
      }
    
      let decodedText = input;
    
      // Decode URL-encoded text
      if (/%[0-9A-Fa-f]{2}/.test(input)) {
        try {
          decodedText = decodeURIComponent(input);
        } catch (error) {
          console.error('Error decoding URL-encoded text:', error);
          return input;
        }
      }
    
      // Store links temporarily
      const links = [];
      let linkCounter = 0;
      decodedText = decodedText.replace(
        /<a[^>]*href=["']([^"']+)["'][^>]*>(.*?)<\/a>/gi,
        (match, url, text) => {
          const placeholder = `__LINK${linkCounter}__`;
          links.push({ placeholder, url, text });
          linkCounter++;
          return placeholder;
        }
      );
    
      // Extract URLs that aren't in anchor tags
      decodedText = decodedText.replace(
        /(https?:\/\/[^\s<]+)/g,
        (match) => {
          const placeholder = `__LINK${linkCounter}__`;
          links.push({ placeholder, url: match, text: match });
          linkCounter++;
          return placeholder;
        }
      );
    
      // Remove HTML tags
      decodedText = decodedText.replace(/<[^>]*>/g, '\n')
        // Replace HTML entities
        .replace(/&nbsp;/g, ' ')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        // Clean up multiple newlines and spaces
        .replace(/\n\s*\n/g, '\n\n')
        .replace(/  +/g, ' ')
        .trim();
    
      // Restore links with target="_blank" and rel="noopener noreferrer"
      links.forEach(({ placeholder, url, text }) => {
        decodedText = decodedText.replace(
          placeholder,
          `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`
        );
      });
    
      return decodedText;
  }

export function fileSizeAndExtension(size: number, file_name?: string) {
    let file_size = size / (1024 * 1024);

    return {
        size: `${file_size.toFixed(2)} MB`,
        extension: file_name ? `.${file_name.split('.').pop()}` : '',
    };
}

export function fileType(file_name?: string) {
    let type = file_name.split('.').pop();
    let img_type = ['jpg', 'jpeg', 'png', 'svg'];
    return img_type.includes(type);
}

export function filteredParams(fields: any) {
    let filters = [];
    Object.keys(fields).forEach((key) => {
        if (fields[key] != '') {
            let control = {};
            control[key] = fields[key];
            filters.push(control);
        }
    });

    return filters;
}

export function getFiltersEntity(key: string) {
    let applied_filter_entity = JSON.parse(localStorage.getItem(key) || '[]');
    let applied_filter_count = applied_filter_entity.length
        ? applied_filter_entity.length
        : null;

    return { applied_filter_entity, applied_filter_count };
}

export function getAppliedFilters(key: string) {
    let applied_filter = '';
    let filters = JSON.parse(localStorage.getItem(key) || '[]');
    let applied_filter_count = filters.length ? filters.length : null;
    let applied_filter_values_list = [];

    if (filters.length) {
        filters.forEach((element) => {
            applied_filter += `${element.key}=${element.value}&`;
            applied_filter_values_list.push({
                type: element.type,
                display_value: element.display_value,
            });
        });
    }

    return {
        applied_filter,
        applied_filter_count,
        applied_filter_values_list,
        filters,
    };
}

export function decodeHTMLEntities(text: any) {
    const entities: any = {
        '&lt;': '<',
        '&gt;': '>',
        '&amp;': '&',
        '&quot;': '"',
        '&#39;': "'",
        '&#x2F;': '/',
        '&#x60;': '`',
        '&#x3D;': '=',
    };

    return text.replace(/&[^;]+;/g, (entity: any) => {
        return entities[entity] || entity;
    });
}

export function generateRandomEightDigitNumber(): number {
    const min = 10000000; // Minimum 8-digit number
    const max = 99999999; // Maximum 8-digit number

    // Generate random number between min and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function calculateSize(file: any) {
    let total_byte = 0;
    let size_in_mb = 0;
    for (let val of file) {
        total_byte += val.size;
    }
    if (total_byte) {
        size_in_mb = total_byte / (1024 * 1024);
    }

    return size_in_mb;
}

export function getMailDraftEditBody(data: any): any {
    let replyBody = `
      ${data?.mail_details?.body}
    `;

    return replyBody;
}

export function decodeHtml(html: string) {
    // Create a temporary DOM element to decode HTML entities
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
}


export function filterAllCountry(universityArr: any) {
    universityArr = universityArr.filter((v:any)=> v?._id != '000');
    return universityArr;
}

export function filterOutActiveUniCountries(uniCountries, tenant){
    uniCountries = uniCountries.filter((unC: any)=> unC?.status == 'true');
    return uniCountries;
}