import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { chatApi } from 'src/app/core/constants/api-constant/chat-api.const';
import { PaginationType } from 'src/app/core/interfaces/pagination/pagination.interface';
import { HttpClientService } from 'src/app/core/services/httpClient/httpClient.service';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    applicatioId$: BehaviorSubject<string> = new BehaviorSubject('');

    startChat = new BehaviorSubject<object>({ startChat: false });
    startChat$ = this.startChat.asObservable();

    constructor(
        private _httpClientService: HttpClientService,
        private _router: Router
    ) {
        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const urlSegments = event.url.split('/');
                const currentPage = urlSegments[3] || '';
                if (currentPage && currentPage === 'view-application') {
                    this.applicatioId$.next(
                        urlSegments[urlSegments.length - 1]
                    );
                } else {
                    this.applicatioId$.next('');
                }
            }
        });
    }

    get getapplicationId(): Observable<string> {
        return this.applicatioId$.asObservable();
    }

    sendMessage(payload: any = {}) {
        return this._httpClientService.chatUpload(
            chatApi.sendMessageInChatRoom,
            payload
        );
    }

    createRoom(payload: any = {}): Observable<any> {
        return this._httpClientService.post(
            chatApi.createSupportChatRooms,
            payload
        );
    }

    closeRoom(
        payload: any = {},
        roomId: string = '',
        withLogin: boolean = true
    ): Observable<any> {
        return this._httpClientService.put(
            `${
                withLogin
                    ? chatApi.closeSupportChatRooms
                    : chatApi.closeSupportChatRoomsWithOutLogin
            }/${roomId}`,
            payload
        );
    }

    getChatRoomMessages(roomId: string = ''): Observable<any> {
        return this._httpClientService.get(
            `${chatApi.getChatRoomsMessages}?referenceId=${roomId}&referenceModule=supportChat`
        );
    }

    createMeeting(payload: any = {}) {
        return this._httpClientService.post(
            `${chatApi.createMeeting}`,
            payload
        );
    }

    assignChat(payload: any = {}, roomId: string = '') {
        return this._httpClientService.put(
            `${chatApi.updateMeeting}/${roomId}`,
            payload
        );
    }

    // getMessages(): Observable<any> {
    //     return new Observable((observer)
    // }

    createRoomWithOutLogin(payload: any = {}): Observable<any> {
        return this._httpClientService.post(
            chatApi.createSupportChatRoomsWithOutLogin.replace(
                ':tempLoginId',
                payload?.tempLoginId
            ),
            payload
        );
    }

    getChatRoomMessagesWithOutLogin(payload: any = {}): Observable<any> {
        return this._httpClientService.get(
            chatApi.getChatRoomsMessagesWithOutLogin.replace(
                ':tempLoginId',
                payload?.tempLoginId
            )
        );
    }

    getChatRoomMessagesWithOutLogiv(
        roomId: string = '',
        tempLoginId
    ): Observable<any> {
        return this._httpClientService.get(
            `${chatApi.getChatRoomsMessages}?referenceId=${roomId}&referenceModule=supportChat&tempLoginId=${tempLoginId}`
        );
    }

    sendMessageWithOutlogin(payload: any = {}) {
        return this._httpClientService.upload(
            chatApi.sendMessageInChatRoomWithoutLogin,
            payload
        );
    }
    getMessageInChatRoomWithoutLogin(
        tempLoginId: string = '',
        pagination: PaginationType = {
            skip: 0,
            limit: 1000,
        }
    ) {
        let query = '?';
        query += `skip=${pagination.skip}&limit=${pagination.limit}&tempLoginId=${tempLoginId}`;
        return this._httpClientService.get(
            `${chatApi.getMessageInChatRoomWithoutLogin}${query}`
        );
    }
}
